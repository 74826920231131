import { CssBaseline } from "@mui/material";
import ReactDOM from "react-dom";
import App from "./App";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import { brown } from "@mui/material/colors";

const coffeeTheme = createTheme({
  palette: {
    primary: {
      main: brown[300]
    }
  },
    typography:{
      fontFamily: ['Indie Flower', 'cursive'].join(',')
    }
});

ReactDOM.render(
  <ThemeProvider theme={coffeeTheme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);