import { AppBar, Box, Grid, Toolbar, Typography } from "@mui/material";

const App = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs>
              <Box component="img" sx={{ height: 48, }} alt="Your logo." src="/logo.svg" />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h4" component="div">
                Dude! Where is my coffee?
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>

      </AppBar>
    </Box>);
}

export default App;